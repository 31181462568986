import logo from './logo.svg';
import './App.css';

import { Route, Routes } from 'react-router-dom';
import About from './pages/about';
import NewsPage from './pages/newsPage';
import Destinations from './pages/interpro';
import Urbano from './pages/urbano';
import Checkout from './pages/checkout';
import Login from './pages/login';
import Admin from './pages/admin';
import axios from 'axios';
import { baseURL } from './api/api';
import { useEffect, useState } from 'react';
import Express from './pages/express';
import NewsArticle from './pages/newsArticle';
import Home from './pages/home';

function App() {
  const updateVisits = () => {
      console.log('inside')
  
    // //('running')
        const visitedOnce = (sessionStorage.getItem('visitedOnce')) || false
          if (!visitedOnce){
            console.log('not visited')
            axios.post(`${baseURL}/addMonthlyView`, {
              dateAdded: new Date()
            })
            .then(res => {
              console.log('ok')
              sessionStorage.setItem('visitedOnce', true)
            })
            .catch(err => {
              console.log('visitedError', err)
              // sessionStorage.setItem('visitedOnce', true)
            })
            sessionStorage.setItem('visitedOnce', true)
  
          }
  }
    // const [homeContent, setHomeContent] = useState(null)
    useEffect(() => {
        
      // axios.get(`${baseURL}/homecontents`)
      // .then(res => {
      //     setHomeContent(res.data)

      // })
      // .catch(err => {
      // })
  
        updateVisits()
        
        
    }, [])
  return (
    <Routes>
      <Route path='/' element={<Home />}/>
      <Route path='/sobre' element={<About />} /> 
      <Route path='/noticias' element={<NewsPage />} /> 
      <Route path='/interprovincial' element={<Destinations />} /> 
      <Route path='/urbano' element={<Urbano />} /> 
      <Route path='/express' element={<Express />} /> 
      <Route path='/checkout' element={<Checkout />} /> 
      <Route path='/login' element={<Login />} /> 
      <Route path='/admin' element={<Admin />} />
      <Route path='/admin/home' element={<Admin />} />
      <Route path='/admin/sobre/sobre' element={<Admin />} />
      <Route path='/admin/sobre/services' element={<Admin />} />
      <Route path='/admin/sobre/frota' element={<Admin />} />
      <Route path='/admin/sobre/diferenciais' element={<Admin />} />
      <Route path='/admin/transporte/urbano' element={<Admin />} />
      <Route path='/admin/transporte/interprovincial' element={<Admin />} />
      <Route path='/admin/transporte/express' element={<Admin />} />

      
      <Route path='/admin/transporte/interprovincial/ponto' element={<Admin />} />
      <Route path='/admin/news' element={<Admin />} />
      <Route path='/news/:id' element={<NewsArticle />} />

      

    </Routes>
  );
}

export default App;
