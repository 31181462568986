import HomeSlide from "../components/homeSlide"
import Header from "../components/header"
import AboutApp from "../components/aboutApp"
import Services from "../components/services"
import Recommendations from "../components/recommendations"
import NewsLetter from "../components/newsletter"
import Download from "../components/download"
import Testimonials from "../components/testimonials"
import Footer from "../components/footer"
import { useEffect, useState } from "react"
import axios from "axios"
import { baseURL } from "../api/api"

const Home = () => {
    const [homeContent, setHomeContent] = useState(null)
    useEffect(() => {
        
        axios.get(`${baseURL}/homecontents`)
        .then(res => {
            setHomeContent(res.data)

        })
        .catch(err => {
        })
    
        
        
    }, [])
    return (
        <div className='homeBody'>

            <div>
              <Header />
              <HomeSlide homeContent={homeContent} />
            </div>

            <AboutApp homeContent={homeContent} />
            <div className='homeBody1'>

            <Services />
            <Recommendations />
            <NewsLetter />
            </div>

            <Download />
            {/* <div className='homeBody1'> */}
            <Testimonials homeContent={homeContent} />
            <Footer />
            {/* </div> */}


        </div>
    )
}
export default Home